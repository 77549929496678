import React, { lazy } from "react";
import { Navigate } from "react-router-dom";

//  Components import
const Home = lazy(() => import("./pages/home"));
const Notfound = lazy(() => import("./pages/404"));
const AboutUs = lazy(() => import("./pages/aboutUs"));
const News = lazy(() => import("./pages/news"));
const ContactUs = lazy(() => import("./pages/ContactUs"));
const Trustees = lazy(() => import("./pages/BoardOfTrustees"));
const TheWise = lazy(() => import("./pages/committeOfTheWise"));
const Auth = lazy(() => import("./pages/auth"));
const Events = lazy(() => import("./pages/events"));
const SingleNews = lazy(() => import("./pages/singleNews"));
const SingleEvent = lazy(() => import("./pages/singleEvents"));
const Stories = lazy(() => import("./pages/stories"));
const SingleStories = lazy(() => import("./pages/singleStories"));
const DonationMethods = lazy(() => import("./pages/donationMethods"));
const OurPartners = lazy(() => import("./pages/outrPartners"));
const OurSectors = lazy(() => import("./pages/ourFields"));
const SingleSectors = lazy(() => import("./pages/singleSector"));
const Causes = lazy(() => import("./pages/causes"));
const SingleCauses = lazy(() => import("./pages/singleCause"));
const Products = lazy(() => import("./pages/products"));
const Project = lazy(() => import("./pages/project"));
const SingleProduct = lazy(() => import("./pages/singleProduct"));
const JoinUsComponent = lazy(() => import("./pages/JoinUsComponent"));
const Cart = lazy(() => import("./pages/cart"));
const Zkaty = lazy(() => import("./pages/zkaty"));
const InkindDonation = lazy(() => import("./pages/inkindDonation"));
const Reseach = lazy(() => import("./pages/Research"));
const Profile = lazy(() => import("./pages/profile"));
const RefugeeSupport = lazy(() => import("./pages/refugeesupport"));
const WomenEmpowerment = lazy(() => import("./pages/WomenEmpowerment"));
const TheIndebted = lazy(() => import("./pages/TheIndebted"));
const Socialsolidarity = lazy(() => import("./pages/social_solidarity"));
const Economic = lazy(() => import("./pages/economic"));
const Industry = lazy(() => import("./pages/Industry"));
const Education = lazy(() => import("./pages/Education"));
const Health = lazy(() => import("./pages/Health"));
const Agriculture = lazy(() => import("./pages/Agriculture"));
const EmailConfirmation = lazy(() => import("./pages/emailConfirmation"));
const ResetPasswordEmail = lazy(() => import("./pages/ResetPasswordEmail"));
const ChangePassword = lazy(() => import("./pages/ChangePassword"));

const Routers = [
    { path: "/", exact: true, element: <Home /> },
    { path: "/login", exact: true, element: <Auth /> },
    { path: "/emailConfirmation/:token", exact: true, element: <EmailConfirmation /> },
    { path: "/resetPasswordEmail", exact: true, element: <ResetPasswordEmail /> },
    { path: "/resetPassword/:token", exact: true, element: <ChangePassword /> },
    { path: "/cart", exact: true, element: <Cart /> },
    { path: "/research", exact: true, element: <Reseach /> },
    { path: "/registration", exact: true, element: <Auth /> },
    { path: "/aboutUs", exact: true, element: <AboutUs /> },
    { path: "/zkaty", exact: true, element: <Zkaty /> },
    { path: "/donations/inkinddonations", exact: true, element: <InkindDonation /> },
    { path: "/donations/products", exact: true, element: <Products /> },
    { path: "/donations/products/:id/:name", exact: true, element: <SingleProduct /> },
    { path: "/news", exact: true, element: <News /> },
    { path: "/events", exact: true, element: <Events /> },
    { path: "/stories", exact: true, element: <Stories /> },
    { path: "/news/:id", exact: true, element: <SingleNews /> },
    { path: "/events/:id/:name", exact: true, element: <SingleEvent /> },
    { path: "/stories/:id/:name", exact: true, element: <SingleStories /> },
    { path: "/contactUs", exact: true, element: <ContactUs /> },
    { path: "/boardOfTrustees", exact: true.valueOf, element: <Trustees /> },
    { path: "/committeeOfTheWise", exact: true.valueOf, element: <TheWise /> },
    { path: "/donations/methods", exact: true.valueOf, element: <DonationMethods /> },
    { path: "/ourpartners", exact: true.valueOf, element: <OurPartners /> },
    { path: "/oursectors", exact: true.valueOf, element: <OurSectors /> },
    { path: "/causes", exact: true.valueOf, element: <Causes /> },
    { path: "/joinUs", exact: true.valueOf, element: <JoinUsComponent /> },
    { path: "/cause/:id/:name", exact: true.valueOf, element: <SingleCauses /> },
    { path: "/sector/:id/:name", exact: true.valueOf, element: <SingleSectors /> },
    { path: "/project/:id/:name", exact: true.valueOf, element: <Project /> },
    { path: "/profile", exact: true.valueOf, element: <Profile /> },
    { path: "/refugeesupport", exact: true.valueOf, element: <RefugeeSupport /> },
    { path: "/womenEmpowerment", exact: true.valueOf, element: <WomenEmpowerment /> },
    { path: "/theIndebted", exact: true.valueOf, element: <TheIndebted /> },
    { path: "/socialsolidarity", exact: true.valueOf, element: <Socialsolidarity /> },
    { path: "/economic", exact: true.valueOf, element: <Economic /> },
    { path: "/industry", exact: true.valueOf, element: <Industry /> },
    { path: "/education", exact: true.valueOf, element: <Education /> },
    { path: "/health", exact: true.valueOf, element: <Health /> },
    { path: "/agriculture", exact: true.valueOf, element: <Agriculture /> },
    { path: "/auth/404", exact: true, element: <Notfound /> },
    { path: "*", element: <Navigate to="/auth/404" /> },
];

export default Routers;
