import React from "react";
import { useRoutes } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import "./App.css";
import Routers from "./Router";
import Main from "./pages/main";

const App = () => {
    const routing = useRoutes(Routers);
    return (
        <div>
            <CssBaseline />
            <Main>{routing}</Main>
        </div>
    );
};

export default App;
