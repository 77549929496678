import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// import parse from "html-react-parser";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Navbar from "components/shared/navbar/Navbar";
import Footer from "components/shared/footer/Footer";
import CustomButton from "components/shared/uikit/customButton/CustomButton";
import Arrow from "assets/icons/ArrowDown.svg";
import solidarity from "assets/icons/solidarity.png";
import whatsapp from "assets/icons/whatsapp.png";
// import Instagram from "assets/icons/instagramHeader.svg";
// import Twitter from "assets/icons/twitterHeader.svg";
// import Linkedin from "assets/icons/linkedin.svg";
import { checkTokenExpiration, getStaticContent } from "services/user.service";
import { useRecoilState, useRecoilValue } from "recoil";
import { navbarOpened, staticContent } from "recoil/atoms";

const Main = ({ children }) => {
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    const opened = useRecoilValue(navbarOpened);
    const [content, setStaticContent] = useRecoilState(staticContent);
    // const instagramLink = content?.filter((i) => i.slug === "instagram-link")[0];
    // const twitterLink = content?.filter((i) => i.slug === "twitter-link")[0];
    // const linkedinLink = content?.filter((i) => i.slug === "linkedin-link")[0];
    const [visible, setVisible] = useState(0);
    const location = useLocation();
    const getScroll = () => {
        if (window.scrollY > 50) {
            setVisible(1);
        } else {
            setVisible(0);
        }
    };
    useEffect(() => {
        getScroll();
    }, [window.scrollY]);
    window.addEventListener("scroll", getScroll);
    useEffect(() => {
        checkTokenExpiration();
        if (!content) {
            getStaticContent().then((res) => setStaticContent(res?.data));
        }
    }, [location?.pathname]);
    return (
        <div dir={i18n.dir()}>
            <div>
            <Navbar />
            </div>
            <Box
                sx={{
                    filter: { xs: opened && "blur(.5rem)", lg: "none" },
                }}
            >
                {children}
            </Box>

            <Box
    sx={{
        position: { xs: !opened ? "fixed" : "none", md: "fixed" },
        display: { xs: "flex", lg: "flex" }, // السماح للزر بالظهور على جميع الأحجام
        bottom: 5,
        left: { xs: 5, md: 50 },
        flexDirection: { xs: "row", md: "column" },
        alignItems: "end",
        justifyContent: "space-around",
        gap: { xs: 1, md: 2 },
        paddingBottom: 3,
        zIndex: 99999,
        overflow: "visible",
    }}
>
    <CustomButton
        sx={{
            backgroundColor: "#FDD73B",
            borderRadius: "50%",
            width: 50,
            height: 60,
            transition: "ease-in-out .2s",
            border: "solid 1px #fff",
            transform: "scale(.8,.8)",
            "&:hover": {
                backgroundColor: "#FDD73B",
                transform: "scale(.83,.83)",
            },
        }}
        text={
            <img
                src={solidarity}
                alt="facebook"
                width="40px"
                height="40px"
            />
        }
        onClick={() => {
            navigate("/donations/methods");
        }}
    />
</Box>


            <CustomButton
                text={
                    <img
                    className="filter"
                    src={Arrow}
                    alt="Arrow Top"
                    style={{
                      width: 20,
                      transform: "rotate(180deg)",
                      filter: "brightness(0) saturate(100%) invert(22%) sepia(7%) saturate(3607%) hue-rotate(334deg) brightness(105%) contrast(84%)",
                    }}
                  />
                  

                }
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                sx={{
                    position: "fixed",
                    bottom: 20,
                    right: 20,
                    width: 65,
                    height: 65,
                    borderRadius: "50%",
                    zIndex: { xs: !opened && 9999, lg: 9999 },
                    transition: "ease-in-out .2s",
                    opacity: opened ? 0 : visible,
                }}
            />

            <Footer opened={opened} />
        </div>
    );
};

export default Main;

Main.propTypes = {
    children: PropTypes.node.isRequired,
};
