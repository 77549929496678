// join us section
export const joinUsWrapper = {
    background: "linear-gradient(to right, #FFF100, #F8D146)", 
    width: "100%",
};
export const joinUsContainer = {
    paddingBlock: "50px",
    backgroundColor: "transparent",  
    width: "100%",
};
export const joinUsTitleWraper = {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
};
export const joinUsTitle = {
    color: "#68402D",
    fontSize: "25px",
};
export const joinUsBtnWraper = {
    display: "flex",
    alignItems: "center",
    justifyContent: { xs: "center", lg: "end" },
};
export const joinUsBtn = {
    color: "#68402D",
    backgroundColor: "#ffffff",
    borderRadius: "25px",
    paddingInline: "35px",
    fontSize: "18px",
    "&:hover": {
        color: "white",
        backgroundColor: "#68402D",
    },
    width: "100%",
    position: "inherit",
};
// footer Section
export const footerWrapper = {
    paddingBlock: "50px",
    backgroundColor: "#68402D",
    width: "100%",
    textAlign: "center",
};
// social section
export const logoSctionWraper = {
    display: "flex",
    flexDirection: "column",
    alignItems: { xs: "center", lg: "unset" },
};
export const footerSocialWrapper = {
    display: "flex",
    justifyContent: "space-between",
    marginBlock: "20px",
    maxWidth: { lg: "150px" },
};
export const footerSocialIcon = {
    backgroundColor: "#ffffff69",
    display: "flex",
    justifyContent: " center",
    alignItems: "center",
    borderRadius: "50%",
    padding: "5px",
    marginInline: { xs: "10px", lg: "0" },
};
export const footerDonate = {
    color: "#010101",
    backgroundColor: "#FDD73B",
    borderRadius: "25px",
    paddingInline: "35px",
    fontSize: "16px",
    width: "190px",
    "&:hover": {
        backgroundColor: "#FDD73B",
    },
};
// News Section
export const flexColumn = {
    display: "flex",
    flexDirection: "column",
};
export const newTitle = (textAlign) => ({
    color: "white",
    fontSize: "22px",
    marginBottom: "30px",
    fontWeight: "700",
    textAlign,
});
export const newsPostWraper = {
    display: "flex",
    alignItems: "center",
    marginBottom: "40px",
};
export const postImage = { width: "150px", borderRadius: "10px" };
export const postContent = {
    display: "flex",
    flexDirection: "column",
    marginInline: "8px",
};
export const postTitle = (textAlign) => ({
    color: "white",
    textAlign,
});
export const postDate = (textAlign) => ({
    fontSize: "14px",
    color: "#cacaca",
    textAlign,
});
// quick Links
export const quickLiksHeader = (textAlign) => ({
    color: "white",
    fontSize: "22px",
    marginBottom: "30px",
    textAlign,
    fontWeight: "700",
});
export const quickLiksWrapper = (textAlign) => ({
    textAlign,
    marginBlock: "10px",
});
export const quickLiks = {
    textDecoration: "none",
    color: "white",
    fontSize: "18px",
    fontWeight: 600,
};

// Have a question
export const haveQuestTitle = (textAlign) => ({
    color: "white",
    fontSize: "22px",
    marginBottom: "30px",
    textAlign,
    fontWeight: "700",
});
export const havequestionSections = {
    display: "flex",
    marginBlock: "10px",
};
export const havequestionText = {
    marginInline: "10px",
    color: "white",
};
