/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type */
import React, { useState } from "react";
import { Link } from "react-router-dom"; // استبدال usePathname بـ useLocation
import { FiMenu, FiX } from "react-icons/fi";
import LogoWithText from "assets/images/LogoWithText.png";
import { useTranslation } from "react-i18next";

const Nav = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { t, i18n } = useTranslation();

    // دالة لإغلاق القائمة
    const closeMenu = () => setIsMenuOpen(false);

    return (
        <div className="relative container mx-auto flex items-center justify-between p-2">
            {/* الشعار */}
            <Link to="/" className="ml-auto lg:ml-0">
                <img src={LogoWithText} alt="LOGO" className="h-[5rem]" />
            </Link>
            {/* الروابط */}
            <div className="hidden lg:flex gap-8">
                <Link
                    to="/aboutUs"
                    className="capitalize font-medium text-lg text-brown-800 p-4"
                >
                    {t("aboutUs.title")}
                </Link>
                <Link
                    to="/oursectors"
                    className="capitalize font-medium text-lg text-brown-800 p-4"
                >
                    {t("navbar.our_sectors")}
                </Link>
                <Link
                    to="/news"
                    className="capitalize font-medium text-lg text-brown-800 p-4"
                >
                    {t("navbar.news")}
                </Link>
                <Link
                    to="/zkaty"
                    className="capitalize font-medium text-lg text-brown-800 p-4"
                >
                    {t("navbar.CalculateYourZakat")}
                </Link>
                <Link
                    to="/joinUs"
                    className="capitalize font-medium text-lg text-brown-800 p-4"
                >
                    {t("navbar.joinUs")}
                </Link>
                <Link
                    to="/donations/methods"
                    className="capitalize font-medium text-lg text-white bg-gradient-to-r from-green-900 to-green-600 p-4 rounded-[5rem]"
                >
                    {t("navbar.methods")}
                </Link>
                <Link
                    to="/contactUs"
                    className="capitalize font-medium text-lg text-white bg-gradient-to-r from-green-900 to-green-600 p-4 rounded-[5rem]"
                >
                    {t("contactUs.phone")}
                </Link>
            </div>
            {/* زر المنيو */}
            <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="lg:hidden text-3xl ml-5"
            >
                {isMenuOpen ? <FiX /> : <FiMenu />}
            </button>

            {isMenuOpen && (
                <div
                    onClick={closeMenu}
                    className="fixed inset-0 bg-black bg-opacity-50 z-[50] lg:hidden"
                 />
            )}

            {/* قائمة التنقل الجانبية */}
            {isMenuOpen && (
                <div className="fixed top-0 left-0 bottom-0 h-screen w-64 bg-white shadow-lg lg:hidden z-[100] transition-transform transform translate-x-0">
                    {/* زر الإغلاق */}
                    <button
                        onClick={closeMenu}
                        className={`absolute top-4 ${i18n.language === "en" ? "right-[1.25rem]" : "left-[1.25rem]"} text-2xl`}
                    >
                        <FiX />
                    </button>

                    <div className="p-4 flex flex-col gap-6">
                        <Link to="/aboutUs" className="capitalize font-medium text-lg" onClick={() => setIsMenuOpen(false)}>
                            {t("aboutUs.title")}
                        </Link>
                        <Link to="/oursectors" className="capitalize font-medium text-lg" onClick={() => setIsMenuOpen(false)}>
                            {t("navbar.our_sectors")}
                        </Link>
                        <Link to="/news" className="capitalize font-medium text-lg" onClick={() => setIsMenuOpen(false)}>
                            {t("navbar.news")}
                        </Link>
                        <Link to="/zkaty" className="capitalize font-medium text-lg" onClick={() => setIsMenuOpen(false)}>
                            {t("navbar.CalculateYourZakat")}
                        </Link>
                        <Link to="/joinUs" className="capitalize font-medium text-lg" onClick={() => setIsMenuOpen(false)}>
                            {t("navbar.joinUs")}
                        </Link>
                        <Link
                            to="/donations/methods"
                            onClick={() => setIsMenuOpen(false)}
                            className="capitalize font-medium text-lg text-white text-center bg-gradient-to-r from-green-900 to-green-600 p-4 rounded-[5rem]"
                        >
                            {t("navbar.methods")}
                        </Link>
                        <Link
                            to="/contactUs"
                            onClick={() => setIsMenuOpen(false)}
                            className="capitalize font-medium text-lg text-white text-center bg-gradient-to-r from-green-900 to-green-600 p-4 rounded-[5rem]"
                        >
                            {t("contactUs.phone")}
                        </Link>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Nav;
